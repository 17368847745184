import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navigation.scss";
import { AuthContext } from "../Context/AuthContext";

import {
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  FormControl,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { AnnouncementPreview } from "../Components/Pages/Settings/Announcements/AnnouncementPreview";
import {
  currentTeamId,
  getUserIndustry,
  getUserTeams,
  getValidationMessage,
  hasClient,
} from "../Utils/utils";
import Session from "../Utils/Session";
import { ANNOUNCEMENTS } from "../GraphApi/Announcement";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getSettings } from "../Utils/CurrentSetting";
import { globalSearch } from "../RestApi/RestApi";
import { SearchListing } from "../Components/Common/Search/SearchListing";
import { ModulePermission } from "../Context/ModulePermission";
import { Notification } from "./Notification";
import copy from "copy-to-clipboard";
import { GENERATE_PIN } from "../GraphApi/TeamMembers";
import { SwitchCompanyPopup } from "../Components/Common/Modals/SwitchCompanyPopup";
import PaymentFailedNotification from "../Components/Common/Notification/TeamNotification/PaymentFailedNotification";
import { TopNotification } from "../Components/Common/Notification/TopNotification";
import DuplicateContactsNotification from "../Components/Common/Notification/DuplicateContactsNotification";
import UnverifiedContactsNotification from "../Components/Common/Notification/TeamNotification/UnverifiedContactsNotification";

const urlSchema = {
  crm: ["affiliate-crm"],
  dashboard: ["dashboard", "edit-dashboard"],
  material: ["marketing-material"],
  profile: ["my-profile"],
  settings: ["settings"],
  contact: ["crm", "contact-details"],
  company: ["company-management", "company-details"],
  sales: ["sales/pipeline", "service-request", "sales/service-request"],
  marketing: ["marketing/automation"],
  engagements: ["engagements"],
};

const AffiliateNavigation = ({ companyLogo, userImage }) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const { pathname } = useLocation();
  const { logOut, userData } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const [industry, setIndustry] = useState("service");
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const [savedPin, setSavedPin] = useState(null);
  const [state, setState] = useState({
    typingTimeout: 0,
  });
  const [handleSearch, setHandleSearch] = useState(false);
  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [shortcutDropdown] = useState(<i className="ri-star-line"></i>);
  const { setOnPage, isShowAll, allowedPages } = useContext(ModulePermission);
  const [allAnnouncements] = useLazyQuery(ANNOUNCEMENTS);
  const [getNewPin] = useMutation(GENERATE_PIN);

  useEffect(() => {
    if (currentTeamId && !Session.getSessionVar("fsp_announcements")) {
      fetchAnnouncements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (userData && userData.authData) {
      const userIndustry = getUserIndustry(userData);
      if (userIndustry) {
        setIndustry(userIndustry);

        document.title = userData.authData.company
          ? userData.authData.company
          : "FieldServicePro";
      }

      setSavedPin(userData?.authData?.pin_data || null);
      if (userData?.authData?.currentTeam?.settings) {
        const companySettings = getSettings(
          userData.authData.currentTeam.settings
        );
        setCurrentCompanySetting(companySettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const fetchAnnouncements = () => {
    allAnnouncements({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    }).then((result) => {
      if (result.data && !result.error) {
        setAnnouncements(result.data.allAnnouncements);
        // dataArrangeByDate(result.data.allAnnouncements);
        if (result.data.allAnnouncements.length > 0) handleShow();
      }
    });
  };

  const handleClose = () => {
    setShowAnnouncements(false);
    Session.storeSessionVar("fsp_announcements", true);
  };
  const handleShow = () => setShowAnnouncements(true);
  const logOutUser = async () => {
    await logOut();
  };

  const navigationDrawerHide = () => {
    if (document.body.clientWidth <= 991) {
      setExpanded(expanded ? false : "expanded");
      updateBodyClass();
    }
  };
  const updateBodyClass = () => {
    if (document.body.classList.contains("modal-open")) {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  };
  /* Handle Search */
  const getSearchValue = (searchedText) => {
    if (searchedText.length >= 3) {
      clearTimeout(state.typingTimeout);
      setState({
        typingTimeout: setTimeout(function () {
          setLoading(true);
          globalSearch({
            team_id: currentTeamId,
            q: searchedText,
          })
            .then((data) => {
              // console.log(data);
              setSearchData(data.data);
              setLoading(false);
            })
            .catch((error) => {
              console.log("search Error:", error);
              setLoading(false);
            });
          setHandleSearch(true);
        }, 600),
      });
    } else if (searchedText.length === 0 || !searchedText) {
      clearTimeout(state.typingTimeout);
      setLoading(false);
      setSearchData(null);
    }
  };
  const refreshPin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getNewPin({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        result.data.updatePin.status && setSavedPin(result.data.updatePin.pin);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log("error", getValidationMessage(graphQLErrors));
      });
  };

  const onCopyMessage = (value) => {
    copy(value, {
      debug: true,
      message: locale("Press #{key} to copy"),
    });
  };
  const pathName = pathname.replace("/", "");

  /*******/
  return (
    <React.Fragment>
      <PaymentFailedNotification />
      {pathName !== "engagements" && (
        <>
          <TopNotification />
          {!hasClient(userData) && (
            <DuplicateContactsNotification url={pathName} />
          )}
          <UnverifiedContactsNotification
            url={pathName}
            locale={locale}
            teamId={currentTeamId}
          />
        </>
      )}
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        expanded={expanded}
        className={hasClient(userData) ? "justify-content-between" : ""}
      >
        <div>
          <Link className="navbar-brand" to="/dashboard">
            {companyLogo}
          </Link>
          <Navbar.Toggle onClick={() => navigationDrawerHide()} />
        </div>
        <Nav className="admin-action-navbar ms-auto">
          {!hasClient(userData) && (
            <React.Fragment>
              <div className="mobile-search-toggle d-xl-none">
                <Button variant="" onClick={handleToggle}>
                  <i className="ri-search-line"></i>
                </Button>
              </div>
              <div
                className={isActive ? "search-bar active" : " search-bar"}
                onBlur={() => setHandleSearch(false)}
              >
                <FormControl
                  type="text"
                  placeholder={locale("pages:navigation.Search")}
                  className="show-search-bar"
                  // onFocus={() => setHandleSearch(false)}
                  onChange={(e) => {
                    getSearchValue(e ? e.target.value : "");
                  }}
                  onBlur={(e) => {
                    e.target.value = "";
                  }}
                  id={"globalSearchBar"}
                  autoComplete="off"
                />
                <Button variant="">
                  <i className="ri-search-line"></i>
                </Button>

                <SearchListing
                  handleSearch={handleSearch}
                  setHandleSearch={setHandleSearch}
                  searchData={searchData}
                  industry={industry}
                  loading={loading}
                  allowedPages={allowedPages}
                  setOnPage={setOnPage}
                />
              </div>
            </React.Fragment>
          )}{" "}
          {!hasClient(userData) && false && (
            <NavDropdown
              align="end"
              className="shortcut-dropdown"
              title={shortcutDropdown}
            >
              <div className="shortcut-title">
                <h4>Shortcuts</h4>
                <a href="javascript:void(0)" className="dropdown-shortcuts-add">
                  <i className="ri-add-circle-line"></i>
                </a>
              </div>
              <div className="shortcut-list-outer">
                <Row>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-calendar-2-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Calendar</h6>
                      <small className="text-muted mb-0">Appointments</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-dashboard-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Dashboard</h6>
                      <small className="text-muted mb-0">User Profile</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-user-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">User App</h6>
                      <small className="text-muted mb-0">Manage Users</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-article-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Invoice App</h6>
                      <small className="text-muted mb-0">Manage Accounts</small>
                    </Link>
                  </Col>
                </Row>
              </div>
            </NavDropdown>
          )}
          <React.Fragment>
            <Notification
              currentCompanySetting={currentCompanySetting}
              userData={userData}
              locale={locale}
              industry={industry}
            />
            <NavDropdown
              title={userImage}
              align="end"
              id="basic-nav-dropdown"
              className="admin-action-dropdown"
            >
              <NavDropdown.Item className={"dropdown-item"}>
                <i className="ri-key-fill"></i> {locale("pages:navigation.Pin")}
                <span className="ms-2">{savedPin}</span>
                <i className="ri-refresh-line ms-2" onClick={refreshPin}></i>
                <i
                  className="ri-file-copy-line text-primary cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCopyMessage(savedPin);
                  }}
                ></i>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/my-profile"
                state={{ industry: industry }}
                className={`dropdown-item ${
                  urlSchema["profile"].includes(pathName) ? "active" : ""
                }`}
              >
                <i className="ri-user-6-line"></i>{" "}
                {locale("pages:navigation.Profile")}
              </NavDropdown.Item>
              <React.Fragment>
                {getUserTeams(userData).length > 1 && <SwitchCompanyPopup />}
                {!hasClient(userData) && (
                  <NavDropdown.Item
                    as={Link}
                    to="/settings"
                    state={{ industry: industry }}
                    className={`dropdown-item ${
                      urlSchema["settings"].includes(pathName) ? "active" : ""
                    }`}
                  >
                    <i className="ri-settings-5-line"></i>{" "}
                    {locale("pages:navigation.Settings")}
                  </NavDropdown.Item>
                )}
              </React.Fragment>
              <NavDropdown.Divider />{" "}
              <NavDropdown.Item onClick={logOutUser}>
                <i className="ri-logout-circle-line"></i>{" "}
                {locale("pages:navigation.Logout")}
              </NavDropdown.Item>
            </NavDropdown>
          </React.Fragment>
        </Nav>
      </Navbar>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        expanded={expanded}
        className={
          hasClient(userData)
            ? "justify-content-between position-sticky top-0"
            : "position-sticky top-0"
        }
        style={{ zIndex: 998 }}
      >
        <Navbar.Collapse id="basic-navbar-nav" className={"primary-navbar"}>
          <Nav className="me-auto ms-xl-5 ps-xl-5">
            <Link
              to="/dashboard"
              className={`nav-link ${
                urlSchema["dashboard"].includes(pathName) ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.Dashboard")}
            </Link>
            <Link
              to="/affiliate-crm"
              className={`nav-link ${
                urlSchema["crm"].includes(pathName) ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.CRM")}
            </Link>{" "}
            <NavDropdown
              title={locale("pages:navigation.Sales")}
              renderMenuOnMount={true}
              className={urlSchema["sales"].includes(pathName) ? "active" : ""}
            >
              <Link
                to="/sales/pipeline"
                state={{ industry: industry }}
                className={`dropdown-item ${
                  urlSchema["sales"].includes(pathName) ? "active" : ""
                }`}
                role="button"
                onClick={() => navigationDrawerHide()}
              >
                {locale("pages:navigation.Sales Pipeline")}
              </Link>
            </NavDropdown>
            <Link
              to="/marketing/automation"
              state={{ industry: industry }}
              className={`nav-link ${
                urlSchema["marketing"].includes(pathName) ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.Automation")}
            </Link>
            <Link
              to="/engagements"
              state={{ industry: industry }}
              className={`nav-link ${
                urlSchema["engagements"].includes(pathName) ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.engagements")}
            </Link>
            <NavDropdown
              title={locale("pages:navigation.Marketing Material")}
              renderMenuOnMount={true}
              className={
                urlSchema["material"].includes(pathName) ? "active" : ""
              }
            >
              <Link
                to="/marketing-material"
                className={`nav-link ${
                  ["/marketing-material"].includes(pathName) ? "active" : ""
                }`}
                role="button"
                onClick={() => navigationDrawerHide()}
              >
                {locale("pages:navigation.Download Broucher")}
              </Link>
              <Link
                to="/marketing-material"
                className={`nav-link ${
                  ["/marketing-material"].includes(pathName) ? "active" : ""
                }`}
                role="button"
                onClick={() => navigationDrawerHide()}
              >
                {locale("pages:navigation.Ads Formats")}
              </Link>
              <Link
                to="/marketing-material"
                className={`nav-link ${
                  ["/marketing-material"].includes(pathName) ? "active" : ""
                }`}
                role="button"
                onClick={() => navigationDrawerHide()}
              >
                {locale("pages:navigation.PPT")}
              </Link>
              <Link
                to="/marketing-material"
                className={`nav-link ${
                  ["/marketing-material"].includes(pathName) ? "active" : ""
                }`}
                role="button"
                onClick={() => navigationDrawerHide()}
              >
                {locale("pages:navigation.Videos")}
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>{" "}
      <AnnouncementPreview
        show={showAnnouncements}
        handleClose={handleClose}
        currentCompanySetting={currentCompanySetting}
        announcements={announcements}
      />
    </React.Fragment>
  );
};

export default AffiliateNavigation;
